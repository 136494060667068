@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

/* FONTS ------------------------------------------------------------------- */
@font-face {
  font-family: 'Motley';
  src: local('Motley'), url(./assets/fonts/Motley/MotleyForcesRegular-w1rZ3.ttf) 
    format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Poppins';
  src: local('Poppins'), url(./assets/fonts/Poppins/Poppins-Thin.ttf)
    format('truetype');
  font-weight: 100;
  font-style: normal;
}
@font-face {
  font-family: 'Poppins';
  src: local('Poppins'), url(./assets/fonts/Poppins/Poppins-ExtraLight.ttf)
    format('truetype');
  font-weight: 200;
  font-style: normal;
}
@font-face {
  font-family: 'Poppins';
  src: local('Poppins'), url(./assets/fonts/Poppins/Poppins-Light.ttf)
    format('truetype');
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: 'Poppins';
  src: local('Poppins'), url(./assets/fonts/Poppins/Poppins-Regular.ttf)
    format('truetype');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'Poppins';
  src: local('Poppins'), url(./assets/fonts/Poppins/Poppins-Medium.ttf)
    format('truetype');
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: 'Poppins';
  src: local('Poppins'), url(./assets/fonts/Poppins/Poppins-SemiBold.ttf)
    format('truetype');
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: 'Poppins';
  src: local('Poppins'), url(./assets/fonts/Poppins/Poppins-Bold.ttf)
    format('truetype');
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: 'Poppins';
  src: local('Poppins'), url(./assets/fonts/Poppins/Poppins-ExtraBold.ttf)
    format('truetype');
  font-weight: 800;
  font-style: normal;
}
@font-face {
  font-family: 'Poppins';
  src: local('Poppins'), url(./assets/fonts/Poppins/Poppins-Black.ttf)
    format('truetype');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto';
  src: local('Roboto'), url(./assets/fonts/Roboto/Roboto-Thin.ttf)
    format('truetype');
  font-weight: 100;
  font-style: normal;
}
@font-face {
  font-family: 'Roboto';
  src: local('Roboto'), url(./assets/fonts/Roboto/Roboto-Light.ttf)
    format('truetype');
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: 'Roboto';
  src: local('Roboto'), url(./assets/fonts/Roboto/Roboto-Regular.ttf)
    format('truetype');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'Roboto';
  src: local('Roboto'), url(./assets/fonts/Roboto/Roboto-Medium.ttf)
    format('truetype');
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: 'Roboto';
  src: local('Roboto'), url(./assets/fonts/Roboto/Roboto-Bold.ttf)
    format('truetype');
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: 'Roboto';
  src: local('Roboto'), url(./assets/fonts/Roboto/Roboto-Black.ttf)
    format('truetype');
  font-weight: 900;
  font-style: normal;
}

/* App Rules --------------------------------------------------------------- */
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.editor-container.editorHasText div:after,
.form-required {

  display: none !important;

}